@media print {
  .edit-button {
    display: none !important;
  }
  
  /* Adicione aqui quaisquer outros estilos de impressão que você deseja */
  body {
    margin: 0mm;
  }
}

.pc-layout {
  width: 1024px; /* Largura típica de um layout de PC */
  
}
